import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { SyncState } from 'matrix-js-sdk/lib/sync';
import sdk from '../../libs/mapp/sdk';
const initialState = {
    activeModal: '',
    userListRoom: '',
    chatMessageDelete: undefined,
    chatShareMessage: undefined,
    uploadModal: undefined,
    chatEnabled: false,
    sidebarCollapsed: false,
    syncState: { state: SyncState.Prepared, lastState: null, data: null },
    apiState: { state: 'ok', message: '' },
    restored: false,
    configuration: {
        agreement: '',
        agreementAccepted: false,
        appName: '',
        availabilityListEnabled: false,
        chatEncryptionOption: 0,
        chatGroupCreationAllowed: false,
        id: '',
        imprint: '',
        logo: '',
        matrixPushServer: '',
        matrixServer: '',
        modules: [],
        privacyPolicy: '',
        singleApp: false,
        termsAccepted: false,
        termsOfUse: '',
        theme: 0,
    },
    state: 'new',
};
export const getConfigurationAsync = createAsyncThunk('app/getConfiguration', async () => {
    const configuration = await sdk.Configuration.get();
    return configuration;
});
export const appSlice = createSlice({
    name: 'app',
    initialState,
    reducers: {
        toggleUserListModal: (state, { payload }) => {
            const { open, room } = payload;
            state.activeModal = open ? 'userRoomList' : '';
            state.userListRoom = room;
        },
        toggleJoinRoomModal: (state, { payload }) => {
            const { open } = payload;
            state.activeModal = open ? 'joinRoomModal' : '';
        },
        toggleChatLeaveRoom: (state, { payload }) => {
            state.chatLeaveRoomModal = { roomId: payload.roomId };
            state.activeModal = 'chatLeaveRoom';
        },
        openUploadModal: (state, { payload }) => {
            state.uploadModal = payload;
            state.activeModal = 'uploadModal';
        },
        openUploadFileModal: (state, { payload }) => {
            state.uploadFileModal = payload;
            state.activeModal = 'uploadFileModal';
        },
        openImageModal: (state, { payload }) => {
            state.imageModal = { url: payload.url };
            state.activeModal = 'imageModal';
        },
        openChatMessageDeleteModal: (state, { payload }) => {
            state.chatMessageDelete = payload;
            state.activeModal = 'chatMessageDeleteModal';
        },
        openShareMessageModal: (state, { payload }) => {
            state.chatShareMessage = payload;
            state.activeModal = 'chatShareMessageModal';
        },
        updateState: (state, { payload }) => {
            state.state = payload;
        },
        updateRestored: (state, { payload }) => {
            state.restored = payload;
        },
        updateSyncState: (state, { payload, }) => {
            state.syncState = payload;
        },
        updateApiState: (state, { payload }) => {
            state.apiState = payload;
        },
        hideActiveModal: (state) => {
            state.activeModal = '';
        },
        updateChatEnabled: (state, { payload }) => {
            state.chatEnabled = payload;
        },
        toggleSidebar: (state) => {
            state.sidebarCollapsed = !state.sidebarCollapsed;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getConfigurationAsync.fulfilled, (state, { payload }) => {
            state.state = 'ready';
            state.configuration = payload;
            return state;
        });
    },
});
export const { toggleUserListModal, toggleJoinRoomModal, toggleChatLeaveRoom, openUploadModal, openUploadFileModal, openImageModal, openChatMessageDeleteModal, openShareMessageModal, updateState, updateChatEnabled, updateRestored, updateSyncState, hideActiveModal, updateApiState, toggleSidebar } = appSlice.actions;
export const selectUserListModal = (state) => {
    return {
        activeModal: state.app.activeModal,
        userListRoom: state.app.userListRoom,
    };
};
export const selectConfiguration = (state) => {
    return state.app.configuration;
};
export const selectChatMessageDeleteModal = (state) => {
    return state.app.chatMessageDelete;
};
export const selectChatLeaveRoomModal = (state) => {
    return state.app.chatLeaveRoomModal;
};
export const selectShareMessage = (state) => {
    return state.app.chatShareMessage;
};
export const selectActiveModal = (state) => {
    return state.app.activeModal;
};
export const selectUploadModal = (state) => {
    return state.app.uploadModal;
};
export const selectUploadFileModal = (state) => {
    return state.app.uploadFileModal;
};
export const selectImageModal = (state) => {
    return state.app.imageModal;
};
export const selectState = (state) => {
    return state.app.state;
};
export const selectRestored = (state) => {
    return state.app.restored;
};
export const selectSyncState = (state) => {
    return state.app.syncState;
};
export const selectApiState = (state) => {
    return state.app.apiState;
};
export const selectChatEnabled = (state) => {
    return state.app.chatEnabled;
};
export const selectSidebarCollapsed = (state) => {
    return state.app.sidebarCollapsed;
};
export default appSlice.reducer;
