import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { Direction } from "matrix-js-sdk/lib/models/event-timeline";
import sdk from '../../libs/mapp/sdk';
import mcli from '../../libs/matrix';
import { getNestedReplyText } from '../../libs/matrix/reply';
const ROOM_STATUS_EVENTS = ['m.room.member', 'm.room.name', 'm.room.topic'];
const paginate = (room, nr, direction) => {
    return new Promise((resolve, reject) => {
        const liveTimeline = room?.getLiveTimeline();
        if (!liveTimeline)
            return;
        mcli.paginateEventTimeline(liveTimeline, {
            backwards: direction === Direction.Backward,
            limit: nr,
        })
            .catch(reject)
            .then((event) => {
            resolve(event);
        });
    });
};
const isDirect = (roomId) => {
    let directFoundRoomId = false;
    const directAccountData = mcli.getAccountData('m.direct');
    const list = directAccountData?.getContent() || [];
    Object.keys(list).forEach((val) => {
        list[val].forEach((id) => {
            if (id === roomId)
                directFoundRoomId = true;
        });
    });
    return directFoundRoomId;
};
const getRoom = async (state, room) => {
    const timeline = room.getLiveTimeline();
    let lastMessage = '';
    let lastEventId = '';
    const events = state?.rooms?.find((r) => r.id === room.roomId)?.events || [];
    const timelineEvents = timeline.getEvents();
    const resolvedMembers = [];
    let isEncrypted = false;
    room.getMembers().forEach((m) => {
        resolvedMembers.push({
            userId: m.userId,
            imageUrl: m.getAvatarUrl(mcli.baseUrl, 49, 49, 'crop', true, true) || '',
            name: m.name,
            membership: m.membership || '',
            typing: false,
            roomId: m.roomId,
            presence: m.user?.presence || '',
        });
    });
    for (const timeLineEvent of timelineEvents) {
        if (timeLineEvent.isEncrypted()) {
            await mcli.decryptEventIfNeeded(timeLineEvent);
        }
        const readEventId = room.getEventReadUpTo(mcli.getUserId() || '');
        if (timeLineEvent.getType() === 'm.room.encryption' ||
            timeLineEvent.isEncrypted())
            isEncrypted = true;
        const id = timeLineEvent?.getId();
        let readState = 0;
        const users = room.getUsersReadUpTo(timeLineEvent);
        const haveRead = resolvedMembers.filter((member) => users.includes(member.userId));
        const otherMembersRead = haveRead.filter((m) => m.userId !== mcli.getUserId());
        readState =
            resolvedMembers.length === haveRead.length
                ? 2
                : otherMembersRead.length === 0
                    ? 1
                    : 2;
        if (typeof id === 'string') {
            if (events.find((ev) => ev.id === id))
                continue;
            events.push({
                id,
                type: timeLineEvent.getType(),
                content: timeLineEvent.getContent(),
                sender: timeLineEvent?.getSender() || '',
                timestamp: timeLineEvent.localTimestamp,
                roomId: timeLineEvent.getRoomId() || '',
                replyTo: timeLineEvent.replyEventId || null,
                readState,
                isStatusEvent: ROOM_STATUS_EVENTS.includes(timeLineEvent.getType()),
                isSending: timeLineEvent.isSending(),
                isEncrypted: timeLineEvent.isEncrypted(),
                isRedacted: timeLineEvent.isRedacted(),
                lastEventRead: readEventId || '',
                threadRootId: timeLineEvent.threadRootId,
                relation: timeLineEvent.getRelation(),
                replyEvent: getNestedReplyText(timeLineEvent),
                usersRead: haveRead,
                isReadByMe: users.includes(mcli.getUserId() || ''),
            });
        }
        if (events.length > 0) {
            const last = timelineEvents[timelineEvents.length - 1];
            lastMessage = last?.getContent().body || '';
            lastEventId = last?.getId() || '';
        }
    }
    let avatar = room.getAvatarUrl(mcli.baseUrl, 80, 80, 'crop');
    if (!avatar && resolvedMembers.length === 2) {
        resolvedMembers.forEach((member) => {
            if (member.userId !== mcli.getUserId()) {
                avatar = member.imageUrl;
            }
        });
    }
    return {
        id: room.roomId,
        name: room?.name,
        lastMessage,
        lastEventId,
        pin: false,
        mute: false,
        imageUrl: avatar || '',
        events,
        members: resolvedMembers.filter((m) => {
            return m.name !== 'matrixadmin' && !m.name.includes('@');
        }),
        userTyping: [],
        isEncrypted,
        isDirect: isDirect(room.roomId),
    };
};
export const getRoomsAsync = createAsyncThunk('matrix/getRoomsAsync', async (_payload, thunkAPI) => {
    const rooms = mcli.getRooms();
    const resolved = [];
    const eventRoom = {};
    for (const room of rooms) {
        try {
            const membership = room.getMyMembership();
            if (membership === 'leave' || membership === 'ban')
                continue;
            if (room.name.includes('Empty room'))
                continue;
            const r = await getRoom(thunkAPI.getState(), room);
            r.events.forEach((ev) => {
                eventRoom[ev.id] = ev.roomId;
            });
            resolved.push(r);
        }
        catch (e) {
            console.error('Error while handling room: ' + room.roomId, room.name);
            console.error(e);
            continue;
        }
    }
    return { rooms: resolved, eventRoom };
});
export const getUsersAsync = createAsyncThunk('matrix/getUsersAsync', async (payload) => {
    const mappUsers = await sdk.ChatUsers.get({
        searchTerm: payload?.searchTerm || '',
        page: payload?.page?.toString() || '1',
    });
    const resolved = [];
    mappUsers.items.forEach((user) => {
        if (user.name === 'matrixadmin' ||
            user.name?.includes('@') ||
            user.matrixId === mcli.getUserId())
            return;
        const matrixUser = mcli.getUser(user.matrixId);
        resolved.push({
            userId: user.matrixId,
            imageUrl: mcli.mxcUrlToHttp(matrixUser?.avatarUrl || '') || '',
            name: user.firstName + ' ' + user.lastName || '',
            membership: '',
            typing: false,
            roomId: '',
            presence: matrixUser?.presence || '',
        });
    });
    return { users: { items: resolved, pagination: mappUsers.pagination } };
});
export const getPublicRooms = createAsyncThunk('matrix/getPublicRooms', async (thunkAPI) => {
    const rooms = await mcli.publicRooms();
    const publicRooms = [];
    rooms.chunk.map((room) => {
        publicRooms.push(room);
    });
    return { publicRooms };
});
export const getRoomAsync = createAsyncThunk('matrix/getRoomAsync', async (id, thunkAPI) => {
    const room = mcli.getRoom(id);
    if (!room)
        return;
    return { room: await getRoom(thunkAPI.getState(), room) };
});
export const paginateRoomAsync = createAsyncThunk('matrix/paginateRoomAsync', async (payload, thunkAPI) => {
    const room = mcli.getRoom(payload.roomId);
    if (!room)
        return;
    const isPaginated = await paginate(room, 20, Direction.Backward);
    if (!isPaginated)
        return;
    return { room: await getRoom(thunkAPI.getState(), room) };
});
const initialState = {
    rooms: [],
    users: {
        items: [],
        pagination: {},
    },
    publicRooms: [],
    eventRoom: {},
    selectedRoom: undefined,
    state: 'loading',
};
const getRoomFullfilled = (state, action) => {
    action.payload?.room.events.forEach((ev) => {
        state.eventRoom[ev.id] = ev.roomId;
    });
    let isFound = false;
    state.rooms = state.rooms.map((r) => {
        if (r.id === action.payload?.room.id) {
            isFound = true;
            return action.payload.room;
        }
        return r;
    });
    if (!isFound && action.payload?.room) {
        state.rooms.push(action.payload.room);
    }
    state.state = 'ready';
};
export const matrixSlice = createSlice({
    name: 'matrix',
    initialState,
    reducers: {
        updateEvent: (state, action) => {
            const evt = action.payload.event;
            const roomId = action.payload?.roomId || state.eventRoom[evt.id];
            if (!roomId)
                return;
            // check if room exist, when not its a new room
            const newRoom = !state.rooms.find((r) => r.id === roomId);
            if (newRoom) {
                const _room = mcli.getRoom(roomId);
                if (!_room)
                    return;
                if (_room?.name.includes('Empty room')) {
                    state.rooms = state.rooms.filter((r) => r.id !== _room.roomId);
                    return;
                }
                state.rooms.push({
                    id: _room.roomId,
                    name: _room?.name || '',
                    lastMessage: '',
                    lastEventId: '',
                    pin: false,
                    mute: false,
                    imageUrl: _room?.getAvatarUrl(mcli.baseUrl, 80, 80, 'crop') || '',
                    events: [evt],
                    members: [],
                    userTyping: [],
                    isEncrypted: evt.type === 'm.room.encryption',
                    isDirect: isDirect(_room.roomId),
                });
            }
            state.eventRoom[evt.id] = roomId;
            let found = false;
            state.rooms = state.rooms.map((r) => {
                if (r.id === roomId) {
                    if (evt.type === 'm.room.encryption')
                        r.isEncrypted = true;
                    r.events = r.events.map((ev) => {
                        if (ev.id === evt.id) {
                            found = true;
                            return evt;
                        }
                        return ev;
                    });
                    if (!found)
                        r.events.push(evt);
                    r.lastMessage =
                        r.events[r.events.length - 1].content?.body || '';
                }
                return r;
            });
        },
        leaveRoom: (state, action) => {
            const roomId = action.payload;
            state.rooms = state.rooms.filter((room) => room.id !== roomId);
        },
        updateMemberTyping: (state, action) => {
            const meId = mcli.getUserId();
            const member = action.payload;
            if (member.userId === meId)
                return;
            const found = state.rooms.find((r) => r.id === member.roomId);
            if (!found)
                return;
            if (!member.typing) {
                found.userTyping = found?.userTyping.filter((m) => m.userId !== member.userId);
            }
            else {
                const memberFound = found.userTyping.find((m) => m.userId === member.userId);
                if (!memberFound) {
                    found.userTyping.push(member);
                }
            }
            state.rooms = state.rooms.map((r) => {
                if (found.id === r.id) {
                    return found;
                }
                return r;
            });
        },
        updateSelectedRoom: (state, action) => {
            const room = mcli.getRoom(action.payload);
            if (!room) {
                state.selectedRoom = '';
                return;
            }
            state.selectedRoom = action.payload;
            return state;
        },
        clear: () => {
            return initialState;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getRoomsAsync.fulfilled, (state, action) => {
            state.rooms = action.payload.rooms;
            state.eventRoom = action.payload.eventRoom;
            state.state = 'ready';
        });
        builder.addCase(getRoomsAsync.pending, (state) => {
            state.state = 'loading';
        });
        builder.addCase(getRoomsAsync.rejected, (state) => {
            state.state = 'error';
        });
        builder.addCase(paginateRoomAsync.fulfilled, getRoomFullfilled);
        builder.addCase(getUsersAsync.fulfilled, (state, action) => {
            state.users = action.payload.users;
            state.state = 'ready';
        });
        builder.addCase(getPublicRooms.fulfilled, (state, action) => {
            state.publicRooms = action.payload.publicRooms;
            state.state = 'ready';
        });
        builder.addCase(getRoomAsync.fulfilled, (state, action) => {
            action.payload?.room.events.forEach((ev) => {
                state.eventRoom[ev.id] = ev.roomId;
            });
            let isFound = false;
            state.rooms = state.rooms.map((r) => {
                if (r.id === action.payload?.room.id) {
                    isFound = true;
                    return action.payload.room;
                }
                return r;
            });
            if (!isFound && action.payload?.room) {
                state.rooms.push(action.payload.room);
            }
            state.state = 'ready';
        });
        builder.addCase(getRoomAsync.pending, (state) => {
            state.state = 'loading';
        });
        builder.addCase(getRoomAsync.rejected, (state) => {
            state.state = 'error';
        });
    },
});
export const { updateSelectedRoom, updateMemberTyping, updateEvent, clear, leaveRoom, } = matrixSlice.actions;
export const selectRooms = (state) => state.matrix.rooms;
export const selectPublicRooms = (state) => state.matrix.publicRooms;
export const selectRoom = (state, roomId) => state.matrix.rooms.find((r) => r.id === roomId);
export const selectUsers = (state) => state.matrix.users;
export const selectState = (state) => state.matrix.state;
export const selectSelectedRoom = (state) => state.matrix.selectedRoom;
export const selectUserTypingByRoomId = (state, roomId) => {
    const f = state.matrix.rooms.find((r) => r.id === roomId);
    return f?.userTyping;
};
export const selectEvent = (state, eventId) => {
    const roomId = state.matrix.eventRoom[eventId];
    if (!roomId)
        return undefined;
    const room = state.matrix.rooms.find((r) => r.id === roomId);
    return room?.events.find((ev) => ev.id === eventId);
};
export const selectMatrixEvent = (state, eventId) => {
    const roomId = state.matrix.eventRoom[eventId];
    if (!roomId)
        return undefined;
    const room = mcli.getRoom(roomId);
    return room
        ?.getLiveTimeline()
        .getEvents()
        .find((ev) => ev.getId() === eventId);
};
export default matrixSlice.reducer;
